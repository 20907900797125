import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Container, Row, Col} from 'react-bootstrap'

import {section, bodyImg} from './styles.module.scss'

import OtherStrains from '../../assets/images/icons/other-strains.inline.svg'
import SeriousConditions from '../../assets/images/icons/serious-conditions.inline.svg'

const WhatIs: React.FC = () => {
  return (
    <section className={`${section} py-5`}>
      <Container>
        <Row id="what-is">
          <Col md={8} xl={7} className="mb-5 mb-md-0">
            <h1 className="mb-3">What are pneumococcal infections?</h1>
            <h2 className="mb-4">(New-mo-kok-al infections)</h2>
            <h2 className="mb-3">Infections caused by pneumococcal bacteria</h2>
            <p className="mb-0">
              Pneumococcal infections are caused by the bacteria called
              <i> streptococcus pneumoniae.</i>
            </p>
          </Col>
          <Col
            md={4}
            xl={5}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <StaticImage
              alt="Pneumococcal Infection icon"
              src={'./img/bacteria.svg'}
              width={250}
              formats={['auto', 'webp', 'avif']}
            />
          </Col>
        </Row>

        <hr className="my-5" />

        <Row>
          <h2 className="mb-3">
            Spread through sneezes, coughs and direct contact
          </h2>
          <p className="mb-4">
            Pneumococcal bacteria are spread in the same way as colds and flu.
            Pneumococcal bacteria can enter your body through your nose and
            mouth by:
          </p>
        </Row>
        <Row className="justify-content-evenly mt-2">
          <Col md={6} lg={4} className="mb-5 mb-md-0">
            <div className="mb-4 d-flex justify-content-center">
              <StaticImage
                alt="Man coughing icon"
                src={'../../assets/images/icons/coffing.svg'}
                layout="fixed"
                width={150}
                formats={['auto', 'webp', 'avif']}
              />
            </div>
            <p className="mb-0">
              Breathing in droplets from an infected person’s sneeze or cough.
            </p>
          </Col>
          <Col md={6} lg={4}>
            <div className="mb-4 d-flex justify-content-center">
              <StaticImage
                alt="Hand with bacteria logo"
                src={'../../assets/images/icons/contaminated-hand.svg'}
                layout="fixed"
                width={150}
                formats={['auto', 'webp', 'avif']}
              />
            </div>
            <p className="mb-0">
              Touching a contaminated object, like a door handle, and then
              touching your nose and mouth.
            </p>
          </Col>
        </Row>

        <hr className="my-5" />

        <Row>
          <h2 className="mb-3">
            May lead to serious conditions in some people
          </h2>
          <p className="mb-4">
            Some strains of pneumococcal bacteria live in people’s nose and
            throat without causing symptoms.
          </p>
        </Row>
        <Row className="justify-content-between">
          <Col lg={6} className="mb-5 mb-lg-0">
            <h2 className="mb-5">
              Other strains can cause infections, such as:
            </h2>
            <div className="d-flex flex-column align-items-center">
              <OtherStrains className={bodyImg} />
            </div>
          </Col>
          <Col lg={6}>
            <h2 className="mb-5">
              More serious pneumococcal conditions include:
            </h2>
            <div className="d-flex flex-column align-items-center">
              <SeriousConditions className={bodyImg} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WhatIs
