import React, {ReactElement} from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap'
import Subtitle from '../Subtitle'
import {
  background,
  heroText,
  title as styleTitle,
  subtitleBox,
  subtitleBottom,
  subtitleImg,
} from './SectionHero.module.scss'

interface Props {
  image: ReactElement
  imageDesktop: ReactElement
  name: string
  complication: 'over 65' | 'diabetes' | 'copd'
  subtitle: {
    firstLine: string
    secondLine: string
  }
}

const SectionHero = (props: Props) => {
  const {image, imageDesktop, name, complication, subtitle} = props
  const isFemale = complication === 'over 65'

  return (
    <div className={background}>
      <Container fluid="lg">
        <Row className="position-relative">
          <Col lg={6} className={heroText}>
            <h2 className={`${styleTitle} pb-0 pt-1 mb-5`}>
              {isFemale ? 'Are you' : 'Do you have'}
              <span>{` ${complication}?`}</span>
            </h2>
            <div>
              <Subtitle>
                {`Meet ${name}, `}
                {isFemale ? 'She is' : 'He has'}
                <span>{` ${complication}.`}</span>
              </Subtitle>
              <br />
              <Subtitle>{subtitle.firstLine}</Subtitle>
              <br />
              <Subtitle>{subtitle.secondLine}</Subtitle>
            </div>
            <div className={subtitleBox}>
              <Subtitle className={subtitleBottom}>
                {`That's why ${
                  isFemale ? 'she' : 'he'
                } got vaccinated and joined`}
              </Subtitle>
              <Image
                src="/images/pneumocrew-logo.svg"
                alt="test"
                className={subtitleImg}
              />
            </div>
          </Col>
          <Col lg={6} className="d-flex d-lg-none justify-content-end p-0 pt-4">
            {image}
          </Col>
          <Col
            lg={6}
            className="d-none d-lg-flex justify-content-center align-items-end p-0"
          >
            {imageDesktop}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SectionHero
