import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Col, Container, Row} from 'react-bootstrap'
import {section, logo, heroText} from './styles.module.scss'

import HeaderLogo from '../../assets/images/header-logo.inline.svg'
import Subtitle from '../../components/Subtitle'

const Awareness: React.FC = () => {
  return (
    <section id="awareness" className={section}>
      <Container fluid="xxl" className="position-relative">
        <Row>
          <Col
            lg={4}
            className="d-flex align-items-center flex-column pt-xl-5 pb-lg-5"
          >
            <HeaderLogo className={logo} />
            <div className={heroText}>
              <Subtitle>
                THEY KNOW THEY’RE AT RISK OF SERIOUS{' '}
                <span>PNEUMOCOCCAL INFECTIONS.</span>
              </Subtitle>
              <br />
              <Subtitle>
                THAT’S WHY THEY RECEIVED THE NHS PNEUMOCOCCAL VACCINATION.
              </Subtitle>
            </div>
          </Col>
          <Col lg={8} className="d-flex align-items-end px-0 pt-lg-5">
            <StaticImage
              className="d-none d-md-block"
              alt="The PneumoCrew: Avani, Jane, Paul and Harry, who are at risk of Pneumococcal infections"
              src={'../../assets/images/people-desktop.png'}
              formats={['auto', 'webp', 'avif']}
            />
            <StaticImage
              className="d-md-none"
              alt="The PneumoCrew: Avani, Jane, Paul and Harry, who are at risk of Pneumococcal infections"
              src={'../../assets/images/people-mobile.png'}
              formats={['auto', 'webp', 'avif']}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Awareness
