import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import {BigButton} from '../../components'
import Video from '../../components/Video'

import {section} from './styles.module.scss'

const Videos: React.FC = () => {
  return (
    <section id="videos" className={`${section} py-5`}>
      <Container>
        <Row>
          <h1 className="mb-4">
            The NHS pneumococcal immunisation programme can help reduce the risk
            of pneumococcal infections for:
          </h1>
        </Row>
        <Row>
          <Col lg="6">
            <h2 className="text-lg-center mb-4">
              People with certain health conditions
            </h2>
            <Video iframe='<iframe title="Video for People with certain health conditions" src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6346276070112" allowFullScreen                    allow="encrypted-media"/>' />
          </Col>
          <Col lg="6">
            <h2 className="text-lg-center mb-4">People aged 65 and over</h2>
            <Video iframe='<iframe title="Video for People aged 65 and over" src="https://players.brightcove.net/4090876667001/default_default/index.html?videoId=6346275274112" allowFullScreen allow="encrypted-media" />' />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <BigButton href="#eligibility">More about eligibility</BigButton>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Videos
