import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import {Container, Row, Col} from 'react-bootstrap'
import {BigButton} from '../../components'

import {section, bigButton} from './styles.module.scss'

const FaqLink: React.FC = () => {
  return (
    <section id="faq-link" className={section}>
      <Container className="position-relative">
        <Row>
          <Col className="d-flex flex-column justify-content-lg-center">
            <h1 className="text-lg-center mt-5 mb-3">Still have questions?</h1>
            <BigButton
              href="/faq"
              className={`${bigButton} w-auto align-self-start align-self-lg-center px-4 my-4`}
            >
              Visit our frequently asked questions
            </BigButton>
            <p className="fw-bold text-lg-center">
              Or speak to a doctor or practice nurse about pneumococcal
              vaccination
            </p>
          </Col>
        </Row>
      </Container>

      <div className="d-flex justify-content-center mt-4">
        <StaticImage
          className="d-none d-sm-block"
          alt="The PneumoCrew: Avani, Jane, Paul and Harry, who are at risk of Pneumococcal infections"
          src={'../../assets/images/people-desktop.png'}
          formats={['auto', 'webp', 'avif']}
        />
        <StaticImage
          className="d-sm-none"
          alt="The PneumoCrew: Avani, Jane, Paul and Harry, who are at risk of Pneumococcal infections"
          src={'../../assets/images/people-mobile.png'}
          formats={['auto', 'webp', 'avif']}
        />
      </div>
    </section>
  )
}

export default FaqLink
