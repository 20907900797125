import React from 'react'
import Container from 'react-bootstrap/Container'
import {BigButton} from '../../components'

import {section} from './styles.module.scss'

const Eligibility: React.FC = () => (
  <section id="eligibility" className={section}>
    <Container className="text-center">
      <p>
        Receiving the free pneumococcal vaccination as part of the NHS national
        immunisation programme makes you a part of the Pneumo Crew, too.
      </p>
      <p>Find out if you’re eligible.</p>
      <BigButton href="#eligibility-calculator">
        Check your eligibility
      </BigButton>
    </Container>
  </section>
)

export default Eligibility
