import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import SectionHero from '../../components/SectionHero'
import {SeriousInfections} from '../../components'

import bloodSugarLevels from '../../assets/images/icons/blood-sugar-levels.svg'
import hospitalTreatment from '../../assets/images/icons/hospital-treatment.svg'
import healthIssues from '../../assets/images/icons/health-issues.svg'
import riskOfDeath from '../../assets/images/icons/risk-of-death.svg'
import Highlight from '../../components/Highlight'

const Diabetes: React.FC = () => {
  return (
    <section id="diabetes" className="d-none">
      <SectionHero
        image={<StaticImage src="./img/harry.png" alt="Harry" width={300} />}
        imageDesktop={
          <StaticImage src="./img/harry-detail.png" alt="Harry" width={550} />
        }
        name="Harry"
        complication="diabetes"
        subtitle={{
          firstLine: 'He knows that puts him at higher risk',
          secondLine: 'of serious pneumococcal infections.',
        }}
      />
      <Container className="pt-5">
        <h1>
          What are the risks of pneumococcal infections if you have diabetes?
        </h1>
        <p className="mb-4">
          You are more likely to get a pneumococcal infection in the first
          place.
        </p>
        <h2 id="diabetes" className="mb-3">
          Diabetes can increase your risk of serious pneumococcal infections
        </h2>

        <Row className="pb-5">
          <Col
            lg={6}
            className="d-flex justify-content-center align-items-centerå"
          >
            <Highlight title="Adults with diabetes are" highlightText="3-6x">
              more likely to get a serious{' '}
              <span className="text-secondary">pneumococcal infection</span> VS
              healthy adults
            </Highlight>
          </Col>
        </Row>

        <h2 className="mb-5">
          You have a higher risk of developing serious infections, such as:
        </h2>
        <SeriousInfections
          list={[
            {
              img: bloodSugarLevels,
              text: 'You may have poor control of your blood sugar levels',
              alt: 'Blood machine icon',
            },
            {
              img: hospitalTreatment,
              text: 'You could need treatment in hospital',
              alt: 'Hospital bed',
            },
            {
              img: healthIssues,
              text: 'An infection might lead to further health issues',
              alt: 'Computer screen with lung icon',
            },
            {
              img: riskOfDeath,
              text: 'You may have a higher risk of complications and death',
              alt: 'Ambulance icon',
            },
          ]}
        />
        <hr />
        <p className="mt-5 mb-4">
          If you have diabetes, getting vaccinated can help protect you against
          serious pneumococcal infections.
          <br />
          <br />
          Speak to your doctor or practice nurse about the NHS pneumococcal
          immunisation programme.
        </p>
        <h1 className="mb-2">Why diabetes may increase your risk</h1>
        <ul className="pb-5">
          <li>You may have a weaker immune system</li>
          <li>
            Diabetes may have damaged your blood vessels or organs, increasing
            your risk of other diseases
          </li>
          <li>
            Your general health may be affected if your diabetes is linked to
            weight, smoking or a lack of healthy foods in your diet
          </li>
        </ul>
      </Container>
    </section>
  )
}

export default Diabetes
