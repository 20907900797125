import React from 'react'
import {
  box,
  title as styleTitle,
  highlightText as styleHighlightText,
  text,
} from './Highlight.module.scss'

interface Props {
  title: string
  highlightText: string
  children: React.ReactNode
}

const Highlight = ({title, highlightText, children}: Props) => {
  return (
    <div className={`d-flex flex-column gap-1 ${box}`}>
      <h3 className={styleTitle}>{title}</h3>
      <div className="d-flex gap-3">
        <span className={styleHighlightText}>{highlightText}</span>
        <span className={text}>{children}</span>
      </div>
    </div>
  )
}

export default Highlight
