import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import SectionHero from '../../components/SectionHero'
import {SeriousInfections} from '../../components'

import exacerbationsImg from '../../assets/images/icons/exacerbations.svg'
import hospitalTreatmentImg from '../../assets/images/icons/hospital-treatment.svg'
import healthIssuesImg from '../../assets/images/icons/health-issues.svg'
import riskOfDeathImg from '../../assets/images/icons/risk-of-death.svg'
import Highlight from '../../components/Highlight'

const COPD: React.FC = () => {
  return (
    <section id="copd" className="d-none">
      <SectionHero
        image={<StaticImage src="./img/paul.png" alt="Paul" width={300} />}
        imageDesktop={
          <StaticImage src="./img/paul-detail.png" alt="Paul" width={550} />
        }
        name="Paul"
        complication="copd"
        subtitle={{
          firstLine: 'He knows that puts him at higher risk',
          secondLine: 'of serious pneumococcal infections.',
        }}
      />
      <Container className="pt-5">
        <h1 className="mb-5">
          COPD can increase your risk of serious pneumococcal infections
        </h1>
        <Row>
          <Col
            lg={6}
            className="d-flex justify-content-center align-items-center pb-3 pb-lg-0"
          >
            <Highlight
              title="Adults with COPD are more than"
              highlightText="16x"
            >
              more likely to get a serious{' '}
              <span className="text-secondary">pneumococcal infection</span> VS
              healthy adults
            </Highlight>
          </Col>
          <Col
            lg={6}
            className="d-flex justify-content-center align-items-center"
          >
            <Highlight title="Around" highlightText="1/3">
              of COPD exacerbations (flare-ups) may be caused by{' '}
              <span className="text-secondary">pneumococcal bacteria</span>
            </Highlight>
          </Col>
        </Row>

        <h2 className="mt-5 mb-3">
          What are the risks of pneumococcal infections with COPD?
        </h2>
        <p className="mb-3">
          You are more likely to get a pneumococcal infection in the first
          place.
        </p>
        <h2 className="mb-5">
          You have a higher risk of developing serious infections, such as:
        </h2>
        <SeriousInfections
          list={[
            {
              img: exacerbationsImg,
              text: 'Your COPD may get worse suddenly and flare up',
              alt: 'Lung with warning sign icon',
            },
            {
              img: hospitalTreatmentImg,
              text: 'You could need treatment in hospital',
              alt: 'Hospital bed icon',
            },
            {
              img: healthIssuesImg,
              text: 'An infection might lead to further health issues',
              alt: 'Computer screen with lung icon',
            },
            {
              img: riskOfDeathImg,
              text: 'You may have a higher risk of complications and death',
              alt: 'Ambulance icon',
            },
          ]}
        />
        <hr />
        <p className="mt-5">
          If you have COPD, getting vaccinated can help protect you against
          serious pneumococcal infections.
        </p>
        <p className="mb-4">
          Speak to your doctor or practice nurse about the NHS pneumococcal
          immunisation programme.
        </p>
        <h1 className="mb-3">Why COPD may increase your risk</h1>
        <ul className="mb-5">
          <li>You may already have weak or damaged lungs</li>
          <li>
            You may have a weakened immune system so your body can&apos;t fight
            off infections as well as people without COPD
          </li>
          <li>
            The medications you take to treat your COPD can increase your risk
            of pneumonia (e.g., corticosteroids)
          </li>
          <li>
            Smoking cigarettes also increases your risk, especially if you have
            COPD
          </li>
        </ul>
      </Container>
    </section>
  )
}
export default COPD
