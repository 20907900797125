import React from 'react'
import {EligibilityCalculator, Footer, SEO} from '../components'

import Awareness from '../sections/Awareness'
import Eligibility from '../sections/Eligibility'
import WhatIs from '../sections/WhatIs'
import Risks from '../sections/Risks'
import Diabetes from '../sections/Diabetes'
import COPD from '../sections/COPD'
import Videos from '../sections/Videos'
import FaqLink from '../sections/FaqLink'

const pageCertificate = {
  jobCode: 'GB-PNX-00429',
  dateOfPrep: 'February 2024',
}

const HomePage: React.FC = () => {
  return (
    <>
      <SEO />
      <main className="content">
        <Awareness />
        <Eligibility />
        <WhatIs />
        <EligibilityCalculator />
        <Risks />
        <Diabetes />
        <COPD />
        <Videos />
        <FaqLink />
      </main>
      <Footer certificate={pageCertificate} />
    </>
  )
}

export default HomePage
