import React from 'react'
import {subtitle} from './Subtitle.module.scss'

interface Props {
  children: React.ReactNode
  className?: string
}

const Subtitle = ({children, className = ''}: Props) => {
  return <p className={`${subtitle} ${className}`}>{children}</p>
}

export default Subtitle
