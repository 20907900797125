import React from 'react'
import {Container} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import {SeriousInfections} from '../../components'

import hospitalTreatment from '../../assets/images/icons/hospital-treatment.svg'
import healthIssues from '../../assets/images/icons/health-issues.svg'
import riskOfDeath from '../../assets/images/icons/risk-of-death.svg'
import SectionHero from '../../components/SectionHero'

const Risks: React.FC = () => {
  return (
    <section id="risks" className="d-none">
      <SectionHero
        image={<StaticImage src="./img/jane.png" alt="Jane" width={300} />}
        imageDesktop={
          <StaticImage src="./img/jane-detail.png" alt="Jane" width={550} />
        }
        name="Jane"
        complication="over 65"
        subtitle={{
          firstLine: "She knows she's at risk of serious",
          secondLine: 'pneumococcal infections.',
        }}
      />
      <Container className="mt-5">
        <h1 className="mb-5">
          What are the risks of pneumococcal infections if you are 65 or over?
        </h1>
        <h2 className="mb-3">
          Age can increase your risk of serious pneumococcal infections
        </h2>
        <p className="mb-4">
          Even if you feel fit and well, when you are aged 65 or over you are at
          increased risk of pneumococcal infections turning into something more
          serious, like pneumonia.
        </p>
        <h2 className="mb-5">
          You have a higher risk of developing serious infections, such as:
        </h2>
        <SeriousInfections
          list={[
            {
              img: hospitalTreatment,
              text: 'You could need treatment in hospital',
              alt: 'Hospital bed',
            },
            {
              img: healthIssues,
              text: 'An infection might lead to further health issues',
              alt: 'Computer screen with lung icon',
            },
            {
              img: riskOfDeath,
              text: 'You may have a higher risk of complications and death',
              alt: 'Ambulance icon',
            },
          ]}
        />
        <hr />
        <p className="my-5">
          If you are 65 or over, a one-off pneumococcal vaccination can help
          protect you against serious pneumococcal infections.
          <br />
          Speak to your doctor or practice nurse about the NHS pneumococcal
          immunisation programme.
        </p>
        <h1 className="mb-3">Why being 65 and over may increase your risk</h1>
        <ul className="mb-5">
          <li>
            Your ability to fight off infections gets weaker as you get older
          </li>
          <li>You might have other health conditions that put you at risk</li>
          <li>
            You may spend time around grandchildren, who spread pneumococcal
            bacteria more easily than adults, because they are more likely to
            have pneumococcal bacteria living naturally in their nose and throat
          </li>
        </ul>
      </Container>
    </section>
  )
}

export default Risks
